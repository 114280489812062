import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useLocation } from '@reach/router'
import Button from '../Button'
import Sidebar from '../Sidebar'
import useToggle from '@/hooks/useToggle'
import { navegation } from '@/data/navegation'
import { IconArrowRight, BarsSolid } from '@/icons'

import { navbar, scroll, link } from './navbar.module.scss'

const Header = ({ pause }) => {
  const [top, setTop] = useState(!!pause)
  const { isOpen, onClose, onOpen } = useToggle()

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    if (pause) return

    const scrollHandler = () => setTop(window.pageYOffset > 10)
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [])
  const { pathname } = useLocation()

  return (
    <>
      <Sidebar isOpen={isOpen} onClose={onClose} />
      <header className={`${navbar} ${top ? scroll : ''}`}>
        <div className="max-w-6xl mx-auto px-5 sm:px-6">
          <div className="relative flex items-center justify-between h-16 md:h-20">
            <button onClick={onOpen} className="absolute md:hidden">
              <BarsSolid className={`w-6 h-6 ${!top ? 'text-white' : ''}`} />
            </button>

            <div className="flex-shrink-0 mx-auto md:mx-0 md:mr-4">
              <Link to="/" className="block" aria-label="Almacen rivera">
                <StaticImage
                  alt="logo"
                  className="w-20 md:w-24"
                  src="../../images/logo.png"
                />
              </Link>
            </div>

            <nav className="flex-grow hidden md:flex">
              <ul className="flex flex-grow justify-center flex-wrap items-center">
                {navegation.map(({ id, path, label }) => (
                  <li key={`ruta-${id}`}>
                    <Link
                      to={path}
                      className={`${link} ${top ? 'text-black' : 'text-white'}  ${pathname === path ? 'text-red-500' : ''} `}
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>

            {/* Site navigation */}
            <nav className="hidden md:flex">
              <ul className="flex flex-grow justify-end flex-wrap items-center">
                <li>
                  <Link href="https://api.whatsapp.com/send?phone=+51998226117&text=Buen%20d%C3%ADa,%20requiero%20informaci%C3%B3n%20sobre%20los%20servicios%20de%20almacenes%20que%20brinda%20%F0%9F%8F%AC%20%F0%9F%9A%9A" target="_blank">
                    <Button
                      size="sm"
                      className="mx-auto md:mx-0"
                      rightIcon={
                        <IconArrowRight className="h-4 w-4 text-white mt-1 ml-2" />
                      }
                    >

                      Contáctanos

                    </Button>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}

export default Header
