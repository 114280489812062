import React from 'react'
import * as styles from './button.module.scss'

const Button = ({
  children,
  leftIcon,
  rightIcon,
  size = 'md',
  className = '',
  colorSchema = 'primary',
  ...props
}) => (
  <button
    {...props}
    className={[styles.btn, styles[size], styles[colorSchema], className].join(
      ' '
    )}
  >
    {leftIcon}
    {children}
    {rightIcon}
  </button>
)

export default Button
