import * as React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({ description = '', lang = 'es', meta = [], title = '', image }) => {
  const defaultTitle = 'Almacen Rivera'
  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang }}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: 'description',
          content: description
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: description
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: image
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:image',
          content: image
        },
        {
          name: 'twitter:creator',
          content: 'Softaki'
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: description
        }
      ].concat(meta)}
    />
  )
}

export default Seo
