import React, { useEffect } from 'react'
import AOS from 'aos'

import Navbar from '../Navbar'
import Footer from '../Footer'

import '@/styles/index.scss'
import IconWhatsapp from '@/icons/IconWhatsapp'
import { Link } from 'gatsby'

const Layout = ({ children, pause }) => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 700,
      disable: 'phone',
      easing: 'ease-out-cubic'
    })
  }, [])

  return (
    <>
      <div className="flex flex-col min-h-screen overflow-hidden">
        <Navbar pause={pause} />
        <main className="flex-grow">{children}</main>
        <Footer />
      </div>
      <div className="relative z-999">
        <Link href="https://api.whatsapp.com/send?phone=+51998226117&text=Buen%20d%C3%ADa,%20requiero%20informaci%C3%B3n%20sobre%20los%20servicios%20de%20almacenes%20que%20brinda%20%F0%9F%8F%AC%20%F0%9F%9A%9A" className="fixed bottom-4 right-4 p-3 bg-green-500 rounded-full" target="_blank">
          <div className="">
            <IconWhatsapp className="w-8 h-8 text-white " />
          </div>
        </Link>
      </div>
    </>
  )
}

export default Layout
