import React from 'react'
import { Link } from 'gatsby'
import { motion } from 'framer-motion'
import { useLocation } from '@reach/router'
import { StaticImage } from 'gatsby-plugin-image'

import IconTimes from '../../icons/IconTimes'

// styles
import * as styles from './sidebar.module.scss'
import { navegation } from '@/data/navegation'

const variants = {
  open: {
    x: 0,
    opacity: 1,
    transition: { ease: 'easeInOut' }
  },
  closed: {
    opacity: 0,
    x: '-100%',
    transition: { ease: 'easeOut' }
  }
}

const fade = {
  open: { opacity: 1 },
  closed: { opacity: 0 }
}

const Sidebar = ({ isOpen = false, onClose = () => {} }) => {
  const { pathname } = useLocation()
  return (
    <aside
      className={styles.sidebar}
      style={{ pointerEvents: isOpen ? 'all' : 'none' }}
    >
      <motion.div
        variants={fade}
        onClick={onClose}
        initial={{ opacity: 0 }}
        className={styles.sidebar_overlay}
        animate={isOpen ? 'open' : 'closed'}
      />
      <motion.div
        variants={variants}
        className={styles.sidebar_content}
        initial={{ opacity: 0, x: '-100%' }}
        animate={isOpen ? 'open' : 'closed'}
      >
        <button onClick={onClose} className={styles.sidebar_buttonClose}>
          <IconTimes className="w-5" />
        </button>

        <StaticImage alt="Logo Beews" src="../../images/logo.png" />

        <ul className={styles.sidebar_links}>
          {navegation.map(({ id, path, label }) => (
            <li key={id} className={pathname === path ? styles.active : ''}>
              <Link to={path}>
                <button onClick={onClose}>{label}</button>
              </Link>
            </li>
          ))}
        </ul>

        <ul className="flex mt-auto justify-center mb-4 md:order-1 md:ml-4 md:mb-0">
          <li>
            <Link
              to="/"
              aria-label="Facebook"
              className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
            >
              <svg
                viewBox="0 0 32 32"
                className="w-8 h-8 fill-current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
              </svg>
            </Link>
          </li>
          <li className="ml-4">
            <Link
              to="/"
              className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
              aria-label="Facebook"
            >
              <svg
                className="w-8 h-8 fill-current"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
              </svg>
            </Link>
          </li>
          <li className="ml-4">
            <Link
              to="/"
              className="flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
              aria-label="Facebook"
            >
              <svg
                className="w-8 h-8 fill-current"
                viewBox="0 0 32 32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
              </svg>
            </Link>
          </li>
        </ul>
      </motion.div>
    </aside>
  )
}

export default Sidebar
