import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import IconMail from '@/icons/IconMail'
import IconFacebook from '@/icons/IconFacebook'
import IconWhatsapp from '@/icons/IconWhatsapp'
import IconPhone from '@/icons/IconPhone'

const Footer = () => {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Top area: Blocks */}
        <div className="flex flex-col gap-8 py-8 md:py-12 border-t border-gray-200">
          {/* 1st block */}
          <div className="mx-auto">
            <div className="mb-2 flex justify-center">
              {/* Logo */}
              <Link to="/" className="inline-block" aria-label="Almacen Rivera">
                <StaticImage
                  alt="logo"
                  width={100}
                  src="../../images/logo.png"
                />
              </Link>
            </div>
            <div className="text-sm text-gray-600 flex items-center gap-x-2">
              <div className="w-3">
                <IconPhone />
              </div>
              <p
                to="#"
                className="text-gray-600 hover:text-gray-900  transition duration-150 ease-in-out"
              >
                998226117
              </p>{' '}
              -{' '}
              <div className="w-3">
                <IconPhone />
              </div>
              <p
                to="#"
                className="text-gray-600 hover:text-gray-900  transition duration-150 ease-in-out"
              >
                947777719
              </p>
            </div>
          </div>

          <ul className="text-sm flex gap-4 mx-auto">
            <li>
              <Link
                to="/"
                className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
              >
                Inicio
              </Link>
            </li>
            <li>
              <Link
                to="/nosotros"
                className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
              >
                Sobre nosotros
              </Link>
            </li>
            <li>
              <Link
                to="/servicios"
                className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
              >
                Servicios
              </Link>
            </li>
            <li>

              <Link
                to="/contactanos"
                className="text-gray-600 hover:text-gray-900 hover:underline transition duration-150 ease-in-out"
              >
                Contactanos
              </Link>
            </li>
          </ul>
        </div>

        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200">
          {/* Social links */}
          <ul className="flex mb-4 md:order-1 md:mb-0 justify-center md:justify-end">
            <li>
              <Link
                to="#"
                aria-label="Facebook"
                className="w-8 h-8 flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
              >
                <IconFacebook />
              </Link>
            </li>
            <li className="ml-4">
              <Link
                to="#"
                aria-label="Whatsapp"
                className="w-8 h-8 flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
              >
                <IconWhatsapp />
              </Link>
            </li>
            <li className="ml-4">
              <Link
                to="#"
                aria-label="Mail"
                className="w-8 h-8 flex justify-center items-center text-gray-600 hover:text-gray-900 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
              >
                <IconMail />
              </Link>
            </li>
          </ul>

          {/* Copyrights note */}
          <div className="text-sm text-gray-600 mr-4">
            Hecho por{' '}
            <a
              className="text-blue-600 hover:underline"
              href="https://google.com/"
            >
              Softaki
            </a>
            . Todos los derechos reservados.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
