export const navegation = [
  {
    id: 'inicio',
    path: '/',
    label: 'Inicio'
  },
  {
    id: 'nosotros',
    path: '/nosotros',
    label: 'Nosotros'
  },
  {
    id: 'servicios',
    path: '/servicios',
    label: 'Servicios'
  },
  {
    id: 'contactanos',
    path: '/contactanos',
    label: 'Contactanos'
  }
]
